<template>
  <v-container fluid>
    <v-dialog v-model="showDraftDialog" width="30%" persistent>
      <v-card height="250px" dense style="font-size: 10px">
        <v-card-title center>{{ $store.state.order.orderNumber }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row style="padding: 0px">
              <v-text-field
                v-model="draftName"
                label="Draft name"
                filled
                autofocus
              >
              </v-text-field>
            </v-row>
            <v-row style="padding: 0px">
              <v-col>
                <v-btn
                  block
                  tile
                  depressed
                  @click="cancelSaveDraftOrder"
                  :disabled="draftLoading"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  block
                  depressed
                  tile
                  @click="saveDraftOrder(draftName)"
                  :loading="draftLoading"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="padless">
      <v-col cols="8">
        <cart-items></cart-items>
      </v-col>
      <v-col>
        <v-row style="padding-top: 2rem">
          <v-col>
            <v-btn
              color="green"
              style="color: white"
              @click="sendToCin7"
              :loading="buttonLoading"
              block
              height="50"
            >
              Submit Order
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="orange"
              style="color: white"
              @click="promptDraftDialog"
              :loading="buttonLoading"
              :disabled="$store.state.order.total === 0"
              block
              height="50"
            >
              {{ saveDraftButtonText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="padless">
      <v-col cols="9">
        <order-assistant></order-assistant>
      </v-col>
      <v-col>
        <v-select
          label="Shipping Address"
          :items="addressOptions"
          v-model="selectedAddress"
        ></v-select>
        <v-text-field
          label="Order Number"
          v-model="$store.state.order.orderNumber"
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <address-card :address="selectedAddressObj" />
      </v-col>
      <v-col>
        <v-textarea
          label="Order Notes"
          v-model="$store.state.order.orderNotes"
          solo
          height="100%"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="1500">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CartItems from "../components/CartItems";
import OrderAssistant from "../components/OrderAssistant";
import AddressCard from "../components/AddressCard";

export default {
  components: {
    CartItems,
    AddressCard,
    OrderAssistant,
  },
  data() {
    return {
      buttonLoading: false,
      draftLoading: false,
      statusMessage: "",
      selectedAddress: "",
      selectedAddressObj: {},
      showDraftDialog: false,
      draftName: "",
      snackbar: false,
      snackbarText: "",
    };
  },
  watch: {
    selectedAddress: function (newVal) {
      const addressId = newVal.split(" ")[0];
      this.selectedAddressObj = this.$store.state.account.shippingAddresses.find((a) => a.id == addressId);
    },
  },
  computed: {
    addressOptions() {
      return this.$store.state.account.shippingAddresses.map(
        (a) => a.id + " - " + a.nickname
      );
    },
    saveDraftButtonText() {
      const activeDraftName = this.$store.state.activeDraft.name;
      if (activeDraftName) return `Overwrite draft "${activeDraftName}"`;
      return "Save Draft Order";
    },
  },
  methods: {
    promptDraftDialog() {
      if (this.$store.state.activeDraft.name) this.saveDraftOrder();
      else this.showDraftDialog = true;
    },
    saveDraftOrder(draftName) {
      this.draftLoading = true;
      this.$store
        .dispatch("saveDraftOrder", draftName)
        .then((res) => {
          if (res.code === 201) {
            this.snackbarText = "Draft saved successfully.";
            this.snackbar = true;
          }
          this.draftLoading = false;
          this.showDraftDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.snackbarText = err;
          this.snackbar = true;
          this.draftLoading = false;
        });
    },
    cancelSaveDraftOrder() {
      this.showDraftDialog = false;
    },
    sendToCin7() {
      //Validation
      if (!this.selectedAddress) return alert('You must select an address first');
      if (this.$store.state.order.total === 0) return alert('Cannot process an order with a cost of 0')

      this.buttonLoading = true;

      const order = {};
      Object.keys(this.$store.state.account).forEach(
        (key) => (order[key] = this.$store.state.account[key])
      );
      Object.keys(this.selectedAddressObj).forEach(
        (key) => (order[key] = this.selectedAddressObj[key])
      );
      Object.keys(this.$store.state.order).forEach(
        (key) => (order[key] = this.$store.state.order[key])
      );
      console.log(order);
      //https://script.google.com/a/straightcurve.com.au/d/1L9swI_OhFe5ox2gdtqb2yD-EQ1zFzkVsOqftwnZ3KLgnT837rQVWGCLL/edit?usp=drive_web
      fetch(
        "https://script.google.com/macros/s/AKfycbwrc74JWVtbazhIZksTRQdWN64sTqHg6H9u_SRLkgmHMv9fTqqm/exec",
        {
          method: "POST",
          body: JSON.stringify({
            username: this.$store.state.account.username,
            order: order,
          }),
        }
      )
        .then((response) =>
          response.json().then((json) => {
            if (json[0].success) {
              console.log(json);
              this.$store.state.account.orders.push(json[0].fullOrder);
              this.$store.commit("resetOrder");
              this.statusMessage = "Order placed!";
              this.selectedAddress = "";
              this.selectedAddressObj = {};
              this.$store.state.activeDraft = {};
            } else {
              this.statusMessage = "Something went wrong.";
            }
            this.buttonLoading = false;
          })
        )
        .catch((err) => {
          console.log('err', err);
          this.buttonLoading = false;
          alert('Order could not be placed.  Please contact Straightcurve for assistance.');
        });
    },
  },
};
</script>

<style>
.padless {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>