<template>
  <v-card min-width="100%">
      <v-card-title><span style="color: orange; margin-right: 5px;">{{address.id}} </span> {{address.nickname}}</v-card-title>
    <v-container>
      <v-row class="denser">
        <v-col class="denser">
          <v-text-field
            label="Street address"
            v-model="address.streetAddress"
            :disabled="editingDisabled"
          ></v-text-field>
        </v-col>
        <v-col class="denser">
          <v-text-field label="City" v-model="address.city" :disabled="editingDisabled"></v-text-field>
        </v-col>
        <v-col class="denser">
          <v-text-field label="Suburb" v-model="address.suburb" :disabled="editingDisabled"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="denser">
        <v-col class="denser">
          <v-text-field label="State" v-model="address.state" :disabled="editingDisabled"></v-text-field>
        </v-col>
        <v-col class="denser">
          <v-text-field
            label="Postal code"
            v-model="address.postalCode"
            :disabled="editingDisabled"
          ></v-text-field>
        </v-col>
        <v-col class="denser">
          <v-text-field
            label="Country"
            v-model="address.country"
            :disabled="editingDisabled"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
    props: {
        address: Object
    },
    data() {
        return {
            editingDisabled: true
        }
    },
};
</script>

<style>
    .denser {
        margin: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
    }
</style>